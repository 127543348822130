import React from "react";
import { connect } from 'react-redux'
import './css/Dashboard.css'
import Dash from '../../images/Dash.png'
import { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";


const StatusChartInfo = {
  series: [44, 55, 41, 17, 15],
  options: {
    chart: {
      type: 'donut',
    },
    title: {
      text: "Todos os Chamados"
    },
    labels: ["Aberto", "Concluído", "Andamento", "Cancelado"],
    responsive: [{
      breakpoint: 200,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  },
};

const MeusStatusChartInfo = {
  series: [44, 55, 41, 17, 15],
  options: {
    chart: {
      type: 'donut',
    },
    title: {
      text: "Meus Chamados"
    },
    labels: ["Aberto", "Concluído", "Andamento", "Cancelado"],
    responsive: [{
      breakpoint: 200,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  },
};







const DashBoard = (props) => {

  const [QuantidadeChamados, setQuantidadeChamados] = useState();
  const [QuantidadeAberto, setQuantidadeAberto] = useState(0);
  const [QuantidadeConcluido, setQuantidadeConcluido] = useState(0);
  const [QuantidadeEmAndamento, setQuantidadeEmAndamento] = useState(0);
  const [QuantidadeCancelado, setQuantidadeCancelado] = useState(0);
  const [MeusQuantidadeAberto, setMeusQuantidadeAberto] = useState(0);
  const [MeusQuantidadeConcluido, setMeusQuantidadeConcluido] = useState(0);
  const [MeusQuantidadeEmAndamento, setMeusQuantidadeEmAndamento] = useState(0);
  const [MeusQuantidadeCancelado, setMeusQuantidadeCancelado] = useState(0);

  const [StatusChart, setStatusChart] = useState({ ...StatusChartInfo });
  const [MeusStatusChart, setMeusStatusChart] = useState({ ...MeusStatusChartInfo });

  useEffect(() => {
    setQuantidadeChamados(props.MeusChamados.length)
  }, [props.MeusChamados]);

  console.log(props.Usuario)

  useEffect(() => {
    setQuantidadeConcluido(props.ChamadosConcluidos.length)
    setQuantidadeCancelado(props.ChamadosCancelados.length)
    setQuantidadeEmAndamento(props.ChamadosAndamento.length)
    setQuantidadeAberto(props.ChamadosAbertos.length)
  }, [props.ChamadosAbertos, props.ChamadosCancelados, props.ChamadosAndamento, props.ChamadosConcluidos]);

  useEffect(() => {
    setMeusQuantidadeConcluido(props.MeusChamadosConcluidos.length)
    setMeusQuantidadeCancelado(props.MeusChamadosCancelados.length)
    setMeusQuantidadeEmAndamento(props.MeusChamadosAndamento.length)
    setMeusQuantidadeAberto(props.MeusChamadosAbertos.length)
  }, [props.MeusChamadosAbertos, props.MeusChamadosCancelados, props.MeusChamadosAndamento, props.MeusChamadosConcluidos]);


  useEffect(() => {
    setStatusChart({ ...StatusChart, series: [QuantidadeAberto, QuantidadeConcluido, QuantidadeEmAndamento, QuantidadeCancelado] })
  }, [QuantidadeConcluido, QuantidadeCancelado, QuantidadeEmAndamento, QuantidadeAberto]);

  useEffect(() => {
    setMeusStatusChart({ ...MeusStatusChartInfo, series: [MeusQuantidadeAberto, MeusQuantidadeConcluido, MeusQuantidadeEmAndamento, MeusQuantidadeCancelado] })
  }, [MeusQuantidadeConcluido, MeusQuantidadeCancelado, MeusQuantidadeEmAndamento, MeusQuantidadeAberto]);


  const state = {

    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },


  };




  const state2 = {

    series: [{
      name: 'Net Profit',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    }, {
      name: 'Revenue',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    }, {
      name: 'Free Cash Flow',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 500
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      },
      yaxis: {
        title: {
          text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    },


  };









  return (
    <div className="Dashboard">


      <p className="DashboardName">{props.Usuario.nome}</p>

      <div className="ChamadosRow">
        <div className="ChamadoRowItem">
          <div className="ChamadoRowQuant AbertoQuant">{props.Usuario.tipo === "Solucionador" ? QuantidadeAberto : MeusQuantidadeAberto}</div>
          <div className="ChamadoRowDesc">Aberto</div>
        </div>
        <div className="ChamadoRowItem">
          <div className="ChamadoRowQuant ConcluidoQuant">{props.Usuario.tipo === "Solucionador" ? QuantidadeConcluido : MeusQuantidadeConcluido}</div>
          <div className="ChamadoRowDesc">Concluido</div>
        </div>
        <div className="ChamadoRowItem">
          <div className="ChamadoRowQuant CanceladoQuant">{props.Usuario.tipo === "Solucionador" ? QuantidadeCancelado : MeusQuantidadeCancelado}</div>
          <div className="ChamadoRowDesc">Cancelados</div>
        </div>
        <div className="ChamadoRowItem">
          <div className="ChamadoRowQuant AndamentoQuant">{props.Usuario.tipo === "Solucionador" ? QuantidadeEmAndamento : MeusQuantidadeEmAndamento}</div>
          <div className="ChamadoRowDesc">Andamento</div>
        </div>
      </div>

      <div className="ChartsPies">

        {props.Usuario.tipo === "Solucionador" &&
          <Chart options={StatusChart.options} series={StatusChart.series} type="pie" width="400px" />
        }

        <Chart options={MeusStatusChart.options} series={MeusStatusChart.series} type="pie" width="400px" />


      </div>

    </div>
  );
}
























const ConnectedDashBoard = connect((state) => {
  return {
    LoggedUser: state.LoggedUser,
    MeusChamados: state.Chamados.filter(Chamado => { return Chamado.Solicitante.EmailSolicitante === state.LoggedUser.email }),
    TodosChamados: state.Chamados,
    Usuario: state.Usuarios.find(Usuario => {
      return Usuario.email === state.LoggedUser.email
    }),
    ChamadosAbertos: state.Chamados.filter(Chamado => { return Chamado.Andamento.Status === 'Aberto' }),
    ChamadosAndamento: state.Chamados.filter(Chamado => { return Chamado.Andamento.Status === 'Em Andamento' }),
    ChamadosCancelados: state.Chamados.filter(Chamado => { return Chamado.Andamento.Status === 'Cancelado' }),
    ChamadosConcluidos: state.Chamados.filter(Chamado => { return Chamado.Andamento.Status === 'Concluído' }),
    MeusChamadosAbertos: state.Chamados.filter(Chamado => { return Chamado.Solicitante.EmailSolicitante === state.LoggedUser.email }).filter(Chamado => { return Chamado.Andamento.Status === 'Aberto' }),
    MeusChamadosAndamento: state.Chamados.filter(Chamado => { return Chamado.Solicitante.EmailSolicitante === state.LoggedUser.email }).filter(Chamado => { return Chamado.Andamento.Status === 'Em Andamento' }),
    MeusChamadosCancelados: state.Chamados.filter(Chamado => { return Chamado.Solicitante.EmailSolicitante === state.LoggedUser.email }).filter(Chamado => { return Chamado.Andamento.Status === 'Cancelado' }),
    MeusChamadosConcluidos: state.Chamados.filter(Chamado => { return Chamado.Solicitante.EmailSolicitante === state.LoggedUser.email }).filter(Chamado => { return Chamado.Andamento.Status === 'Concluído' })
  }
})(DashBoard)

export default ConnectedDashBoard

